import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import * as d3 from 'd3';
import './App.css';

const NAKSHATRAS = [
  'Ashvini', 'Bharani', 'Krittika', 'Rohini', 'Mrigashira', 'Ardra',
  'Punarvasu', 'Pushya', 'Ashlesha', 'Magha', 'Purva Phalguni', 'Uttara Phalguni',
  'Hasta', 'Chitra', 'Svati', 'Vishakha', 'Anuradha', 'Jyeshtha',
  'Mula', 'Purva Ashadha', 'Uttara Ashadha', 'Shravana', 'Dhanishta', 'Shatabhisha',
  'Purva Bhadrapada', 'Uttara Bhadrapada', 'Revati'
];

const RITUS = [
  { name: 'Vasanta', englishName: 'Spring', startMonth: 2, endMonth: 3 },
  { name: 'Grīṣma', englishName: 'Summer', startMonth: 4, endMonth: 5 },
  { name: 'Varsha', englishName: 'Monsoon', startMonth: 6, endMonth: 7 },
  { name: 'Śarada', englishName: 'Autumn', startMonth: 8, endMonth: 9 },
  { name: 'Hemanta', englishName: 'Pre-winter', startMonth: 10, endMonth: 11 },
  { name: 'Śiśira', englishName: 'Winter', startMonth: 0, endMonth: 1 }
];

const AYANAMSA = 24.1;

const PLANETS = [
  { name: 'Sun', color: '#FDB813', offset: 0 },
  { name: 'Moon', color: '#F4F1C9', offset: 10 },
  { name: 'Mercury', color: '#E6C229', offset: 20 },
  { name: 'Venus', color: '#EC6B56', offset: 30 },
  { name: 'Mars', color: '#E94F37', offset: 40 },
  { name: 'Jupiter', color: '#17BEBB', offset: 50 },
  { name: 'Saturn', color: '#B2967D', offset: 60 }
];

const FESTIVALS = [
  { name: 'Makar Sankranti', date: '14-Jan' },
  { name: 'Vasant Panchami', date: '5-Feb' },
  { name: 'Maha Shivaratri', date: '11-Mar' },
  { name: 'Holi', date: '29-Mar' },
  { name: 'Gudi Padwa', date: '2-Apr' },
  { name: 'Rama Navami', date: '21-Apr' },
  { name: 'Baisakhi', date: '14-Apr' },
  { name: 'Akshaya Tritiya', date: '3-May' },
  { name: 'Buddha Purnima', date: '15-May' },
  { name: 'Rath Yatra', date: '20-Jun' },
  { name: 'Guru Purnima', date: '13-Jul' },
  { name: 'Raksha Bandhan', date: '22-Aug' },
  { name: 'Janmashtami', date: '31-Aug' },
  { name: 'Ganesh Chaturthi', date: '10-Sep' },
  { name: 'Onam', date: '20-Sep' },
  { name: 'Navaratri', date: '7-Oct' },
  { name: 'Dussehra', date: '15-Oct' },
  { name: 'Karwa Chauth', date: '1-Nov' },
  { name: 'Diwali', date: '4-Nov' },
  { name: 'Bhai Dooj', date: '6-Nov' },
  { name: 'Chhath Puja', date: '10-Nov' },
  { name: 'Guru Nanak Jayanti', date: '19-Nov' }
];

const AjaiBhambiNakshatraDashboard = () => {
  const [displayDate, setDisplayDate] = useState(new Date());
  const [planetPositions, setPlanetPositions] = useState([]);
  const [currentRitu, setCurrentRitu] = useState(RITUS[0]);
  const [isRealTime, setIsRealTime] = useState(true);
  const [simulationSpeed, setSimulationSpeed] = useState(1);
  const svgRef = useRef(null);

  const zodiacSigns = [
    'Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo',
    'Libra', 'Scorpio', 'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces'
  ];

  const calculateCelestialPositions = (date) => {
    const J2000 = new Date('2000-01-01T12:00:00Z');
    const julianDays = (date - J2000) / (1000 * 60 * 60 * 24);
    const T = julianDays / 36525;

    const calculatePlanetPosition = (L0, L1, L2, L3, L4, L5) => {
      let L = L0 + L1 * T + L2 * T * T + L3 * T * T * T + L4 * T * T * T * T + L5 * T * T * T * T * T;
      L = L % 360;
      if (L < 0) L += 360;
      return (L - AYANAMSA + 360) % 360;
    };

    const sunLongitude = calculatePlanetPosition(280.46646, 36000.76983, 0.0003032, 0, 0, 0);
    const moonLongitude = calculatePlanetPosition(218.3164477, 481267.88123421, -0.0015786, 1 / 538841, -1 / 65194000, 0);
    const mercuryLongitude = calculatePlanetPosition(48.3313, 48.2465583, 3.24587E-5, 0, 0, 0);
    const venusLongitude = calculatePlanetPosition(76.6799, 224.7008188, -0.0002796, 0, 0, 0);
    const marsLongitude = calculatePlanetPosition(49.5574, 19.3727661, 3.10E-7, 0, 0, 0);
    const jupiterLongitude = calculatePlanetPosition(100.4542, 10.4664397, 1.02E-6, 0, 0, 0);
    const saturnLongitude = calculatePlanetPosition(113.6634, 4.2261314, 0, 0, 0, 0);

    return PLANETS.map(planet => {
      let longitude;
      switch(planet.name) {
        case 'Sun': longitude = sunLongitude; break;
        case 'Moon': longitude = moonLongitude; break;
        case 'Mercury': longitude = mercuryLongitude; break;
        case 'Venus': longitude = venusLongitude; break;
        case 'Mars': longitude = marsLongitude; break;
        case 'Jupiter': longitude = jupiterLongitude; break;
        case 'Saturn': longitude = saturnLongitude; break;
        default: longitude = 0;
      }
      return {
        name: planet.name,
        color: planet.color,
        ...calculateNakshatraPosition(longitude),
        sign: zodiacSigns[Math.floor(longitude / 30)]
      };
    });
  };

  const calculateNakshatraPosition = (longitude) => {
    const nakshatraIndex = Math.floor(longitude / (360 / 27));
    const degreeInNakshatra = (longitude % (360 / 27)).toFixed(2);
    return {
      longitude: longitude.toFixed(2),
      nakshatra: NAKSHATRAS[nakshatraIndex],
      degree: degreeInNakshatra
    };
  };

  const calculatePanchang = (date) => {
    const moonPosition = planetPositions.find(p => p.name === 'Moon');
    const sunPosition = planetPositions.find(p => p.name === 'Sun');
    
    if (!moonPosition || !sunPosition) return null;

    const tithi = ((moonPosition.longitude - sunPosition.longitude + 360) % 360) / 12;
    const tithiIndex = Math.floor(tithi);
    const tithiNames = [
      'Pratipada', 'Dwitiya', 'Tritiya', 'Chaturthi', 'Panchami',
      'Shashthi', 'Saptami', 'Ashtami', 'Navami', 'Dashami',
      'Ekadashi', 'Dwadashi', 'Trayodashi', 'Chaturdashi', 'Purnima',
      'Pratipada', 'Dwitiya', 'Tritiya', 'Chaturthi', 'Panchami',
      'Shashthi', 'Saptami', 'Ashtami', 'Navami', 'Dashami',
      'Ekadashi', 'Dwadashi', 'Trayodashi', 'Chaturdashi', 'Amavasya'
    ];

    const yoga = (sunPosition.longitude + moonPosition.longitude) % 360 / 13.333333;
    const yogaIndex = Math.floor(yoga);
    const yogaNames = [
      'Vishkumbha', 'Priti', 'Ayushman', 'Saubhagya', 'Shobhana',
      'Atiganda', 'Sukarma', 'Dhriti', 'Shula', 'Ganda',
      'Vriddhi', 'Dhruva', 'Vyaghata', 'Harshana', 'Vajra',
      'Siddhi', 'Vyatipata', 'Variyan', 'Parigha', 'Shiva',
      'Siddha', 'Sadhya', 'Shubha', 'Shukla', 'Brahma',
      'Indra', 'Vaidhriti'
    ];

    const karana = tithi % 1 * 2;
    const karanaIndex = Math.floor(karana);
    const karanaNames = [
      'Bava', 'Balava', 'Kaulava', 'Taitila', 'Gara',
      'Vanija', 'Vishti', 'Shakuni', 'Chatushpada', 'Naga'
    ];

    return {
      tithi: tithiNames[tithiIndex],
      yoga: yogaNames[yogaIndex],
      karana: karanaNames[karanaIndex]
    };
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (isRealTime) {
        const now = new Date();
        setDisplayDate(now);
      } else {
        setDisplayDate(prevDate => {
          const newDate = new Date(prevDate);
          newDate.setSeconds(newDate.getSeconds() + simulationSpeed);
          return newDate;
        });
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [isRealTime, simulationSpeed]);

  useEffect(() => {
    const positions = calculateCelestialPositions(displayDate);
    setPlanetPositions(positions);
    setCurrentRitu(determineRitu(displayDate));
  }, [displayDate]);

  useEffect(() => {
    if (svgRef.current) {
      const svg = d3.select(svgRef.current);
      const width = svg.node().clientWidth;
      const height = svg.node().clientHeight;
      const centerX = width / 2;
      const centerY = height / 2;
      const radius = Math.min(width, height) / 2 - 80;

      svg.selectAll("*").remove();

      const gradient = svg.append("defs")
        .append("radialGradient")
        .attr("id", "sky-gradient")
        .attr("cx", "50%")
        .attr("cy", "50%")
        .attr("r", "50%");

      gradient.append("stop")
        .attr("offset", "0%")
        .attr("stop-color", "#0a0a23");

      gradient.append("stop")
        .attr("offset", "100%")
        .attr("stop-color", "#000012");

      svg.append("circle")
        .attr("cx", centerX)
        .attr("cy", centerY)
        .attr("r", radius + 70)
        .attr("fill", "url(#sky-gradient)");

      svg.append("circle")
        .attr("cx", centerX)
        .attr("cy", centerY)
        .attr("r", radius)
        .attr("fill", "none")
        .attr("stroke", "#4a5568")
        .attr("stroke-width", 2);

      NAKSHATRAS.forEach((nakshatra, index) => {
        const angle = (index / 27) * 2 * Math.PI - Math.PI / 2;
        const x = centerX + radius * Math.cos(angle);
        const y = centerY + radius * Math.sin(angle);

        svg.append("line")
          .attr("x1", centerX)
          .attr("y1", centerY)
          .attr("x2", x)
          .attr("y2", y)
          .attr("stroke", "#1e293b")
          .attr("stroke-width", 1);

        svg.append("text")
          .attr("x", centerX + (radius + 30) * Math.cos(angle))
          .attr("y", centerY + (radius + 30) * Math.sin(angle))
          .attr("text-anchor", "middle")
          .attr("alignment-baseline", "middle")
          .attr("fill", "#a0aec0")
          .attr("font-size", "12px")
          .text(nakshatra);
      });

      planetPositions.forEach(planet => {
        const planetAngle = (planet.longitude / 360) * 2 * Math.PI - Math.PI / 2;
        svg.append("circle")
          .attr("cx", centerX + radius * 0.8 * Math.cos(planetAngle))
          .attr("cy", centerY + radius * 0.8 * Math.sin(planetAngle))
          .attr("r", planet.name === 'Sun' || planet.name === 'Moon' ? 15 : 10)
          .attr("fill", planet.color);

        svg.append("text")
          .attr("x", centerX + radius * 0.8 * Math.cos(planetAngle))
          .attr("y", centerY + radius * 0.8 * Math.sin(planetAngle) + 25)
          .attr("text-anchor", "middle")
          .attr("fill", planet.color)
          .attr("font-size", "14px")
          .text(planet.name);
      });
    }
  }, [planetPositions]);

  const determineRitu = (date) => {
    const month = date.getMonth();
    return RITUS.find(ritu => month >= ritu.startMonth && month <= ritu.endMonth) || RITUS[0];
  };

  const handleDateChange = (e) => {
    const [year, month, day] = e.target.value.split('-').map(Number);
    const newDate = new Date(year, month - 1, day, displayDate.getHours(), displayDate.getMinutes());
    setDisplayDate(newDate);
    setIsRealTime(false);
  };

  const handleTimeChange = (e) => {
    const [hours, minutes] = e.target.value.split(':').map(Number);
    const newDate = new Date(displayDate);
    newDate.setHours(hours, minutes);
    setDisplayDate(newDate);
    setIsRealTime(false);
  };

  const resetToRealTime = () => {
    setDisplayDate(new Date());
    setIsRealTime(true);
  };

  const handleSpeedChange = (e) => {
    setSimulationSpeed(Number(e.target.value));
  };

  const LineGraph = ({ title, data, xKey, yKey, color }) => {
    const svgRef = useRef(null);

    useEffect(() => {
      const svg = d3.select(svgRef.current)
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', '0 0 400 300')
        .attr('preserveAspectRatio', 'xMidYMid meet');

      svg.selectAll("*").remove();

      const margin = { top: 20, right: 30, bottom: 40, left: 50 };
      const width = 400 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;

      const g = svg.append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      const x = d3.scaleBand()
        .domain(data.map(d => d[xKey]))
        .range([0, width])
        .padding(0.1);

      const y = d3.scaleLinear()
        .domain([0, d3.max(data, d => d[yKey])])
        .nice()
        .range([height, 0]);

      g.append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x))
        .selectAll('text')
        .attr('fill', '#a0aec0');

      g.append('g')
        .attr('class', 'axis axis--y')
        .call(d3.axisLeft(y).ticks(5))
        .selectAll('text')
        .attr('fill', '#a0aec0');

      const line = d3.line()
        .x(d => x(d[xKey]) + x.bandwidth() / 2)
        .y(d => y(d[yKey]));

      g.append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', color)
        .attr('stroke-width', 2)
        .attr('d', line);

      g.selectAll('.dot')
        .data(data)
        .enter().append('circle')
        .attr('class', 'dot')
        .attr('cx', d => x(d[xKey]) + x.bandwidth() / 2)
        .attr('cy', d => y(d[yKey]))
        .attr('r', 4)
        .attr('fill', color);

      g.append('text')
        .attr('x', width / 2)
        .attr('y', 0 - margin.top / 2)
        .attr('text-anchor', 'middle')
        .style('font-size', '16px')
        .style('fill', '#a0aec0')
        .text(title);

      g.append('text')
        .attr('transform', `translate(${width/2}, ${height + margin.bottom - 5})`)
        .style('text-anchor', 'middle')
        .style('fill', '#a0aec0')
        .text('Planets');

      g.append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - margin.left)
        .attr('x', 0 - (height / 2))
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .style('fill', '#a0aec0')
        .text('Longitude (degrees)');

    }, [data, xKey, yKey, color, title]);

    return <svg ref={svgRef} className="graph"></svg>;
  };

  const panchang = calculatePanchang(displayDate);

  return (
    <Container fluid className="nk-dashboard">
      <h1 className="nk-title">Nakshatra Planetary Analyzer: 1.0</h1>
      
      <Row>
        <Col lg={8} className="mb-8 mx-auto">
          <svg ref={svgRef} width="100%" height="600" className="mx-auto"></svg>
        </Col>
      </Row>
      
      <Row>
        <Col lg={12} className="mb-4 mx-auto">
          <Card className="nk-card mb-4">
            <Card.Body>
              <Card.Title className="nk-card-title">Simulation Data</Card.Title>
              <Card.Text id="simulation-data">
                Date: {displayDate.toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' })}<br />
                Time: {displayDate.toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata' })}<br />
                Current Ritu: {currentRitu.name} ({currentRitu.englishName})<br />
                <br />
                Panchang:<br />
                Tithi: {panchang?.tithi}<br />
                Yoga: {panchang?.yoga}<br />
                Karana: {panchang?.karana}<br />
                <br />
                {planetPositions.map(planet => (
                  <div key={planet.name}>
                    <strong className="nk-data-label" style={{ color: planet.color }}>{planet.name} Position</strong><br />
                    Longitude: {planet.longitude}°<br />
                    Nakshatra: {planet.nakshatra}<br />
                    Degree in Nakshatra: {planet.degree}°<br />
                    Sign: {planet.sign}<br />
                    <br />
                  </div>
                ))}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={4} className="mb-4">
          <Card className="nk-card">
            <Card.Body>
              <Card.Title className="nk-card-title text-center">Date and Time Selector</Card.Title>
              <Form>
                <Form.Group controlId="formDate">
                  <Form.Control
                    type="date"
                    value={displayDate.toISOString().split('T')[0]}
                    onChange={handleDateChange}
                    className="bg-gray-700 text-white mb-4"
                  />
                </Form.Group>
                <Form.Group controlId="formTime">
                  <Form.Control
                    type="time"
                    value={`${displayDate.getHours().toString().padStart(2, '0')}:${displayDate.getMinutes().toString().padStart(2, '0')}`}
                    onChange={handleTimeChange}
                    className="bg-gray-700 text-white mb-4"
                  />
                </Form.Group>
                <Form.Group controlId="formSpeed">
                  <Form.Label>Simulation Speed</Form.Label>
                  <Form.Control
                    type="range"
                    min="1"
                    max="100"
                    value={simulationSpeed}
                    onChange={handleSpeedChange}
                    className="mb-4"
                  />
                </Form.Group>
                <Button variant="success" className="nk-button w-full" onClick={resetToRealTime}>Reset to Real Time</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="mb-4">
          <Card className="nk-card">
            <Card.Body>
              <Card.Title className="nk-card-title text-center">Indian Festivals</Card.Title>
              <ul>
                {FESTIVALS.map(festival => {
                  const festivalDate = new Date(displayDate.getFullYear(), parseInt(festival.date.split('-')[1]) - 1, parseInt(festival.date.split('-')[0]));
                  return (
                    <li key={festival.name}>
                      {festival.name}: {festivalDate.toLocaleDateString('en-IN')}
                    </li>
                  );
                })}
              </ul>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="mb-4">
          <Card className="nk-card">
            <Card.Body>
              <Card.Title className="nk-card-title text-center">Planetary Aspects</Card.Title>
              <ul>
                {planetPositions.map((planet, index) => 
                  planetPositions.slice(index + 1).map(otherPlanet => {
                    const aspect = ((otherPlanet.longitude - planet.longitude + 360) % 360).toFixed(2);
                    let aspectType = '';
                    if (aspect >= 0 && aspect < 10 || aspect >= 350 && aspect <= 360) aspectType = 'Conjunction';
                    else if (aspect >= 55 && aspect <= 65) aspectType = 'Sextile';
                    else if (aspect >= 85 && aspect <= 95) aspectType = 'Square';
                    else if (aspect >= 115 && aspect <= 125) aspectType = 'Trine';
                    else if (aspect >= 175 && aspect <= 185) aspectType = 'Opposition';
                    return (
                      <li key={`${planet.name}-${otherPlanet.name}`}>
                        {planet.name} - {otherPlanet.name}: {aspect}° ({aspectType})
                      </li>
                    );
                  })
                ).flat()}
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12} className="mb-4">
          <Card className="nk-card">
            <Card.Body>
              <Card.Title className="nk-card-title text-center">Planetary Movements</Card.Title>
              <div className="graphs-container">
                <LineGraph 
                  title="Sun and Moon Positions" 
                  data={planetPositions.filter(p => p.name === 'Sun' || p.name === 'Moon')} 
                  xKey="name" 
                  yKey="longitude" 
                  color="#f6ad55" 
                />
                <LineGraph 
                  title="Inner Planets Positions" 
                  data={planetPositions.filter(p => ['Mercury', 'Venus', 'Mars'].includes(p.name))} 
                  xKey="name" 
                  yKey="longitude" 
                  color="#63b3ed" 
                />
                <LineGraph 
                  title="Outer Planets Positions" 
                  data={planetPositions.filter(p => ['Jupiter', 'Saturn'].includes(p.name))} 
                  xKey="name" 
                  yKey="longitude" 
                  color="#48bb78" 
                />
                <LineGraph 
                  title="Nakshatra Degrees" 
                  data={planetPositions} 
                  xKey="name" 
                  yKey="degree" 
                  color="#9f7aea" 
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <footer className="nk-footer">
        <p>Simulation based on: 1 year = 6 seasons, 12 months = 12 poornima and 12 amavasya</p>
        <p>Moon crosses 27 nakshatras in 27.32 days, Sun crosses 27 nakshatras in 365.25 days</p>
        <p>1 nakshatra length ≈ 13.33 degrees</p>
        <p>© 2023 Dr. Ajai Bhambi's Nakshatra Planetary Analyzer</p>
      </footer>
    </Container>
  );
};

export default AjaiBhambiNakshatraDashboard;